import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import HeaderIdea from "../components/HeaderIdea";
import Footer from "../components/Footer";
import Information from "../components/Information";
import SEO from "../components/SEO";

const MainStyles = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* max-width: 1440px; */
  margin: 0 auto;
`;

const FooterStyles = {
  marginTop: "100px",
};

function Ideas({ data }) {
  const posts = data?.allSanityPost?.nodes;

  return (
    <MainStyles>
      <SEO title="Ideas" />
      <HeaderIdea />
      <Information posts={posts} />
      <Footer style={{ FooterStyles }} />
    </MainStyles>
  );
}

export default Ideas;

export const query = graphql`
  query {
    allSanityPost(sort: { order: DESC, fields: publishedAt }) {
      nodes {
        title
        description
        publishedAt
        slug {
          current
        }
        categories {
          title
        }
      }
    }
  }
`;
